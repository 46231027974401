.card-img-top {
  width: 100%;
  height: 20vw;
  object-fit: cover;
}

/*.card-content, .card-overlay, .card-details, .fadeIn-bottom*/
.card-content {
  position: relative;
  overflow: hidden;
}

.card-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.card-content:hover .card-overlay {
  opacity: 1;
}

.card-details {
  position: absolute;
  text-align: center;
  padding: 5.5rem 1rem;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.card-content:hover .card-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.card-details p {
  color: #fff;
  font-size: 2vw;
}

.card-body h5 {
  font-size: 2em;
}

.card-body p,
.card-footer p {
  font-size: 1em;
}

.fadeIn-bottom {
  top: 80%;
}

@media only screen and (max-width: 768px) {
  .card-img-top {
    width: 100%;
    height: 60vw;
    object-fit: cover;
  }

  .card-details p {
    font-size: 5vw;
  }
}
